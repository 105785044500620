import clsx from "clsx";
import Image from "next/image";
import s from "./styles.module.sass";
import loaderPrimarySrc from "../../../../public/icons/loaderPrimary.svg";
import loaderWhiteSrc from "../../../../public/icons/loaderWhite.svg";
import { ImageProps } from "next/dist/client/image";

export type ButtonProps = {
	text: string;
	isLoading?: boolean;
	icon?: ImageProps["src"];
	iconPosition?: "start" | "end";
	size?: "small" | "medium" | "large" | "header" | "firstsection";
	variant?:
		| "primary_filled"
		| "primary_outlined"
		| "secondary_filled"
		| "secondary_outlined"
		| "tertiary"
		| "quinary"
		| "transparent_blue";
	upperCase?: boolean;
	fullWidth?: boolean;
} & JSX.IntrinsicElements["button"];

const Button = ({
	text,
	isLoading,
	icon,
	iconPosition,
	size = "large",
	variant = "primary_filled",
	upperCase,
	fullWidth,
	onClick,
	...props
}: ButtonProps) => {
	const renderButtonContent = () => {
		if (isLoading) {
			return (
				<div className={s.loader}>
					<Image
						src={variant === "primary_filled" ? loaderWhiteSrc : loaderPrimarySrc}
						width={18}
						height={18}
						alt="Загрузка"
					/>
				</div>
			);
		}

		if (icon !== undefined && iconPosition === "start") {
			return (
				<>
					<div className={`${s.iconWrapper} ${s.startIcon}`}>
						<Image src={icon} layout="fill" objectFit="contain" alt={text} />
					</div>
					{text}
				</>
			);
		}

		if (icon !== undefined && iconPosition === "end") {
			return (
				<>
					{text}
					<div className={`${s.iconWrapper} ${s.endIcon}`}>
						<Image src={icon} layout="fill" objectFit="contain" alt={text} />
					</div>
				</>
			);
		}

		return text;
	};

	return (
		<button
			type={"button"}
			disabled={props.disabled || isLoading}
			onClick={onClick}
			{...props}
			className={clsx(
				s.button,
				{
					[s.fullWidth]: fullWidth,
					[s.primaryOutlined]: variant === "primary_outlined",
					[s.secondaryFilled]: variant === "secondary_filled",
					[s.transparentBlue]: variant === "transparent_blue",
					[s.secondaryOutlined]: variant === "secondary_outlined",
					[s.tertiary]: variant === "tertiary",
					[s.quinary]: variant === "quinary",
					[s.smallSize]: size === "small",
					[s.header]: size === "header",
					[s.firstsection]: size === "firstsection",
					[s.disabled]: props.disabled || isLoading,
					[s.upperCase]: upperCase,
					[s.iconed]: icon,
				},
				props.className
			)}
		>
			{renderButtonContent()}
		</button>
	);
};

export default Button;
