type AuthProviderProps = {
	isAuth: boolean;
	renderAuthComponent: () => JSX.Element;
	renderUnAuthComponent: () => JSX.Element;
};

/**
 *
 * @param isAuth - авторизован ли пользователь
 * @param renderAuthComponent - компонент для авторизованного пользователя
 * @param renderUnAuthComponent - компонент для неавторизованного пользователя

   Данный провайдер необходимо использовать в том случае если компонент имеет разный визуал в зависимости от авторизации
 */
export const AuthProvider = ({ isAuth, renderAuthComponent, renderUnAuthComponent }: AuthProviderProps) => {
	return <>{isAuth ? renderAuthComponent() : renderUnAuthComponent()}</>;
};
