type FormatCurrencyOptions = {
	currency: "RUB";
	maximumFractionDigits: number;
	style?: "currency" | "decimal" | "percent";
	minimumFractionDigits?: number;
	useGrouping?: boolean;
};

const defaultFormatCurrencyOptions: FormatCurrencyOptions = {
	style: "currency",
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
	useGrouping: true,
	currency: "RUB",
};

// Возвращает строку формата `7 000,55 Р`
export default function formatNumberToCurrency(
	number: number,
	options: FormatCurrencyOptions = defaultFormatCurrencyOptions
) {
	return number !== 0 ? new Intl.NumberFormat("ru-RU", options).format(number) : `0 ₽`;
}
