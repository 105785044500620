import React from "react";
import { AuthProvider } from "@shared/providers/AuthProvider";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import AuthInfoCourseCard from "@components/UI/InfoCourseCard/AuthInfoCourseCard";
import InfoCourseCardBase from "@components/UI/InfoCourseCard/InfoCourseCardBase";
import { InfoCourseCardBaseType } from "@components/UI/InfoCourseCard/InfoCourseCardBase/InfoCourseCard.typedef";
import { toggleNeuralModalOpenStatus, updateNeuralQAEvent } from "src/features/NeuralQAModal/model/public";
import { $favouriteCourses, toggleFavouriteCourse } from "@features/FavouriteCourses/model/public";
import { $isAuth } from "../../../process/authProcess/model/public";
import { NeuralQA } from "@features/NeuralQAModal/model/typedef";

type InfoCourseCardProps = InfoCourseCardBaseType;

const InfoCourseCard = ({ course, variant, isFill, removeFunction, withMaxHeight }: InfoCourseCardProps) => {
	const isAuth = useStore($isAuth);
	const favCourses = useStore($favouriteCourses);
	const toggleFavouriteCourseEvent = useEvent(toggleFavouriteCourse);
	const toggleNeuralModal = useEvent(toggleNeuralModalOpenStatus);
	const updateNeuralQuestionAndAnswer = useEvent(updateNeuralQAEvent);
	const foundFavoriteCourse = favCourses?.items?.find((item) => {
		return course.id === item.id;
	});

	const isFavorite = foundFavoriteCourse !== undefined;

	const openNeuralModal = (qa: NeuralQA[]) => {
		toggleNeuralModal(true);

		updateNeuralQuestionAndAnswer(qa);
	};
	const infoCourseCardBaseProps: InfoCourseCardBaseType = {
		variant,
		course,
		isFill,
		removeFunction,
		onNeuralIconClick: openNeuralModal,
		withMaxHeight,
	};
	return (
		<AuthProvider
			isAuth={isAuth}
			renderAuthComponent={() => {
				return (
					<AuthInfoCourseCard
						{...infoCourseCardBaseProps}
						isFavorite={isFavorite}
						addToFavorite={toggleFavouriteCourseEvent}
					/>
				);
			}}
			renderUnAuthComponent={() => {
				return <InfoCourseCardBase {...infoCourseCardBaseProps} />;
			}}
		/>
	);
};

export default InfoCourseCard;
