import { COLLECTIONS_IDS } from "../constants/COLLECTIONS_IDS";
import { CourseContentType, UploadFilesType } from "@interfaces/constructor";
import { COURSE_LEVELS } from "src/constants/COURSE_LEVELS";
import draftIcon from "../../public/icons/draftGreyFilled.svg";
import publishedIcon from "../../public/icons/checkPrimaryFilled.svg";
import clockIcon from "../../public/icons/clockGreyFilled.svg";
import errorIcon from "../../public/icons/error.svg";
import { IAsset, UploadFileStatusesType } from "@interfaces/courseElements";
import { UPLOAD_FILE_STATUSES } from "src/constants/statuses";
import { COURSE_CONTENT_TYPE } from "src/constants/COURSE_CONTENT_TYPE";
import { Asset } from "@features/Course/model/typedef";
import { Course } from "@generated/index";

type CollectionsCortege = [COLLECTIONS_IDS, COLLECTIONS_IDS, COLLECTIONS_IDS];
export const getCollectionsByCategoryUrl = (categoryUrl: string): CollectionsCortege | [] => {
	switch (categoryUrl) {
		case "development": {
			return [
				COLLECTIONS_IDS.DEVELOPMENT_START,
				COLLECTIONS_IDS.WEB_DEV_POPULAR,
				COLLECTIONS_IDS.BACK_DEV_POPULAR,
			];
		}
		case "design": {
			return [
				COLLECTIONS_IDS.DESIGN_START,
				COLLECTIONS_IDS.GRAPHIC_EDITORS_POPULAR,
				COLLECTIONS_IDS.UIUX_BEST,
			];
		}
		case "marketing": {
			return [
				COLLECTIONS_IDS.MARKETING_START,
				COLLECTIONS_IDS.SEARCH_MARKETING_POPULAR,
				COLLECTIONS_IDS.SOCIAL_MARKETING_BEST,
			];
		}
		case "business": {
			return [
				COLLECTIONS_IDS.BUSINESS_START,
				COLLECTIONS_IDS.ENTERPRISE_POPULAR,
				COLLECTIONS_IDS.FREELANCE_BEST,
			];
		}
		default: {
			return [];
		}
	}
};

// получение лейбла по типу контента
export const getLabel = (type: CourseContentType) => {
	if (type === COURSE_CONTENT_TYPE.LECTURES) {
		return "Лекция";
	}
	if (type === COURSE_CONTENT_TYPE.TESTS) {
		return "Тест";
	}
	if (type === COURSE_CONTENT_TYPE.QUIZ_CARDS) {
		return "Квиз";
	}
};

// получить дефолтную секцию для блока лекция / тест / квиз с материалами
export const getDefaultSection = (type: CourseContentType) => {
	if (type === COURSE_CONTENT_TYPE.LECTURES) return "materials";
	if (type === COURSE_CONTENT_TYPE.TESTS) return "questions";
	if (type === COURSE_CONTENT_TYPE.QUIZ_CARDS) return "terms";

	return "editBlockName";
};

// получить название статуса курса
export const getCourseStatusData = (status: Course["status"]) => {
	switch (status) {
		case "draft":
			return {
				icon: draftIcon,
				status: "Черновик",
			};
		case "review":
			return {
				icon: clockIcon,
				status: "На проверке",
			};
		case "uploading":
			return {
				icon: draftIcon,
				status: "Видео в обработке",
			};
		case "published":
			return {
				icon: publishedIcon,
				status: "Опубликован",
			};
		case "deleted":
			return {
				icon: draftIcon,
				status: "Удалён",
			};
		case "rejected":
			return {
				icon: errorIcon,
				status: "Отклонён",
			};
		default:
			return {
				icon: "/",
				status: "Неизвестно",
			};
	}
};

// название уровня курса
export const getLevelName = (level: string) => {
	if (level === COURSE_LEVELS.BEGINNER) {
		return "Начальный";
	}
	if (level === COURSE_LEVELS.MIDDLE) {
		return "Средний";
	}
	if (level === COURSE_LEVELS.ADVANCED) {
		return "Продвинутый";
	}
	return "";
};

// создание локального объекта лекции, теста или квиза
export const createLocalSectionAsset = (type: CourseContentType, assetId: number): Asset => {
	if (type === COURSE_CONTENT_TYPE.LECTURES)
		return {
			id: assetId,
			title: "",
			unsaved: true,
			video: "",
			description: "",
			file: "",
			key: assetId.toString(),
			free: false,
			terms: [],
		};
	if (type === COURSE_CONTENT_TYPE.TESTS)
		return {
			id: assetId,
			title: "",
			video: "",
			unsaved: true,
			questions: [],
			questionAmount: 0,
			key: assetId.toString(),
			free: false,
			terms: [],
		};
	if (type === COURSE_CONTENT_TYPE.QUIZ_CARDS)
		return {
			id: assetId,
			video: "",
			title: "",
			unsaved: true,
			terms: [],
			termAmount: 0,
			key: assetId.toString(),
			free: false,
		};
	// По дефолту возвращаем новый квиз. Сделано для того, чтобы функция не возвращала undefined
	return {
		id: assetId,
		video: "",
		title: "",
		unsaved: true,
		terms: [],
		termAmount: 0,
		key: assetId.toString(),
		free: false,
	};
};

// получить тип контента в секции (лекция, тест, квиз)
export const getSectionAssetType = (block: IAsset) => {
	const isVideo = block.video !== undefined;
	const isQuestions = block.questions !== undefined;
	const isTerms = block.terms !== undefined;
	if (isVideo) {
		return COURSE_CONTENT_TYPE.LECTURES;
	}
	if (isQuestions) {
		return COURSE_CONTENT_TYPE.TESTS;
	}
	if (isTerms) {
		return COURSE_CONTENT_TYPE.QUIZ_CARDS;
	}
	return COURSE_CONTENT_TYPE.LECTURES;
};

// get status name
export const getUploadFileStatus = (
	isSaved: boolean,
	fileType: UploadFilesType,
	uploadStatus?: UploadFileStatusesType,
	errorMessage?: string
) => {
	if (uploadStatus === UPLOAD_FILE_STATUSES.DELIVERING) return "Загружается";
	if (
		fileType === "video" &&
		(uploadStatus === UPLOAD_FILE_STATUSES.UPLOADING || uploadStatus === UPLOAD_FILE_STATUSES.DELIVERED)
	)
		return "В обработке";
	if (uploadStatus === UPLOAD_FILE_STATUSES.SUCCESS) return "Загружено";
	if (uploadStatus === UPLOAD_FILE_STATUSES.FAIL || uploadStatus === UPLOAD_FILE_STATUSES.NOT_DELIVERED)
		return "Ошибка" + `: ${errorMessage}`;

	return isSaved ? "Загружено" : "Добавлено";
};
