import React, { MouseEventHandler } from "react";
import UnAuthInfoCourseCard from "@components/UI/InfoCourseCard/InfoCourseCardBase";
import clsx from "clsx";
import s from "@components/UI/InfoCourseCard/InfoCourseCardBase/styles.module.sass";
import { InfoCourseCardBaseType } from "@components/UI/InfoCourseCard/InfoCourseCardBase/InfoCourseCard.typedef";

type AuthInfoCourseCardProps = {
	isFavorite: boolean;
	addToFavorite: ({ id }: { id: number }) => void;
} & InfoCourseCardBaseType;

const AuthInfoCourseCard = ({ isFavorite, addToFavorite, ...props }: AuthInfoCourseCardProps) => {
	const { course } = props;
	const handleFavClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();

		addToFavorite({
			id: course.id,
		});
	};

	return (
		<UnAuthInfoCourseCard {...props}>
			<button
				className={clsx(s.favButton, {
					[s.faved]: isFavorite,
				})}
				type="button"
				onClick={handleFavClick}
			>
				<div className={s.favIcon} />
			</button>
		</UnAuthInfoCourseCard>
	);
};

export default AuthInfoCourseCard;
