import React, { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import Image from "next/image";
import starIcon from "../../../../../public/icons/star.svg";
import s from "./styles.module.sass";
import { getLevelName } from "src/utils/formatByConditions";
import Chips from "@components/UI/Chips";
import CardPaper from "@components/UI/CardPaper";
import { InfoCourseCardBaseType } from "@components/UI/InfoCourseCard/InfoCourseCardBase/InfoCourseCard.typedef";
import { pluralForm } from "@shared/functions/pluralForm";
import { calcFinalPrice } from "@shared/functions/calcFinalPrice";
import defaultCover from "../../../../../public/images/photoLoaderBg.png";
import formatNumberToCurrency from "@shared/functions/formatNumberToCurrency";
import { roundNumber } from "@shared/functions/roundNumber";
import { useWebAnalyticsContext } from "@shared/analytics/webAnalytics.contexts";
import checkAndPrepareRating from "@shared/functions/checkAndPrepareRating";

type InfoCourseCardBaseProps = InfoCourseCardBaseType & {
	children?: ReactNode;
};

const InfoCourseCardBase = ({
	course,
	variant,
	isFill,
	removeFunction,
	children,
	onNeuralIconClick,
	withMaxHeight = false,
}: InfoCourseCardBaseProps) => {
	const [isHorizontalAvailable, setIsHorizontalAvailable] = useState(false);
	const isHorizontal = variant === "horizontal" && isHorizontalAvailable;
	const { clickProduct } = useWebAnalyticsContext();
	const { title, coverUrl, rating, reviewsAmount } = course;
	const authors = course.author && `${course.author.firstName} ${course.author.lastName}`;

	const { discount, commission, cost } = course;
	/* Наличие рейтинга == наличию отзывов. Если поставить оценку с пустым отзывом, то это будет считаться как отзыв,
	 * поэтому в данной ситуации мы можем скрыть весь блок. Несмотря на то, что достаточно проверить 1 условие (т.к. они взаимозависимы)
	 * эта проверка сделана более явной.
	 */
	const isTestimonialsVisible = rating !== 0 && reviewsAmount !== 0;

	const originalPrice = cost !== 0 ? roundNumber(cost) : 0;
	const priceWithDiscount = calcFinalPrice({
		cost,
		discount,
	});

	const tags = [
		{
			id: 1,
			title: course.category?.title || "",
		},
		{
			id: 2,
			title: getLevelName(course.level),
		},
	];

	const checkResize = () => {
		setIsHorizontalAvailable(window.innerWidth > 960);
	};

	useEffect(() => {
		checkResize();
		window.addEventListener("resize", checkResize, false);
		return () => {
			window.removeEventListener("resize", checkResize);
		};
	}, []);

	const removeFromCart: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		if (removeFunction !== undefined) {
			removeFunction();
		}
	};

	const showNeuralQuestionAndAnswer: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();

		if (onNeuralIconClick !== undefined) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			onNeuralIconClick(course.qa);
		}
	};

	const selectProduct = () => {
		clickProduct({
			category: course.category?.title || "",
			discount: course.discount,
			price: roundNumber(course.cost),
			id: course.id.toString(),
			name: course.title,
		});
	};

	const preparedRating = checkAndPrepareRating(rating);

	return (
		<CardPaper
			onClick={selectProduct}
			className={clsx(s.card, {
				[s.horizontal]: isHorizontal,
				[s.fill]: isFill,
				[s.maxHeight]: withMaxHeight,
			})}
			href={`/course/${course.url}`}
		>
			<div
				className={clsx(s.imageWrapper, {
					[s.horizontal]: isHorizontal,
				})}
			>
				<Image
					loader={
						typeof coverUrl === "string"
							? () => {
									return coverUrl;
							  }
							: undefined
					}
					unoptimized
					src={coverUrl || defaultCover}
					layout="fill"
					objectFit="cover"
					alt={title}
					priority
				/>
				{children}
			</div>

			<div
				className={clsx(s.cardInfo, {
					[s.horizontal]: isHorizontal,
				})}
			>
				<div>
					<div className={s.tags}>
						<Chips chips={tags} />
					</div>
					<h3 className={s.title}>{title}</h3>
					<div className={s.authors}>{authors}</div>
					{isTestimonialsVisible && (
						<div className={s.testimonials}>
							<div className={s.rating}>
								<Image src={starIcon} width={21} height={21} alt="Star" />
								<span className={s.rating}>{preparedRating}</span>
							</div>
							<span>
								({reviewsAmount} {pluralForm(reviewsAmount, "отзыв", "отзыва", "отзывов")})
							</span>
						</div>
					)}

					{removeFunction && (
						<div className={s.cartAction}>
							<button className={s.cartActionDel} onClick={removeFromCart}>
								Удалить из заказа
							</button>
						</div>
					)}
				</div>

				<div
					className={clsx(s.price, {
						[s.horizontal]: isHorizontal,
					})}
				>
					<span className={s.currentPrice}>
						{formatNumberToCurrency(!!discount || !!commission ? priceWithDiscount : originalPrice)}
					</span>
					{course.discount !== 0 && (
						<p className={s.oldPrice}>
							<s>{formatNumberToCurrency(originalPrice)}</s>
						</p>
					)}
				</div>
				{course.qa !== null && (
					<button className={s.neuroNetwork} onClick={showNeuralQuestionAndAnswer}>
						<Image src={"/icons/neuro-network.svg"} alt="Нейросеть" objectFit="contain" layout="fill" />
					</button>
				)}
			</div>
		</CardPaper>
	);
};

export default InfoCourseCardBase;
