import clsx from "clsx";
import s from "./styles.module.sass";
import { ChipsProps } from "@components/UI/Chips/Chips.typedef";

const Chips = ({ size = "medium", chips, color = "primary" }: ChipsProps) => {
	return (
		<div className={s.chips}>
			{chips.map((item, index) => {
				return (
					item.title && (
						<div
							className={clsx(s.item, {
								[s.tertiary]: color === "tertiary",
								[s.secondary]: color === "secondary",
								[s.small]: size === "small",
							})}
							key={index}
						>
							<p className={s.title}>{item.title}</p>
						</div>
					)
				);
			})}
		</div>
	);
};

export default Chips;
