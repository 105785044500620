// _START - начините с этих курсов, _POPULAR - популярные, _MAIN - на главной странице, _BEST - лучшие
export enum COLLECTIONS_IDS {
	PYTHON_MAIN,
	JAVASCRIPT_MAIN,
	EXCEL_MAIN,
	TEST_SOFTWARE_MAIN,
	PHOTOSHOP_MAIN,
	DATABASE_MAIN,
	MARKETING_START,
	SEARCH_MARKETING_POPULAR,
	SOCIAL_MARKETING_BEST = 11,
	DEVELOPMENT_START,
	WEB_DEV_POPULAR,
	BACK_DEV_POPULAR,
	BUSINESS_START,
	ENTERPRISE_POPULAR,
	FREELANCE_BEST,
	DESIGN_START,
	GRAPHIC_EDITORS_POPULAR,
	UIUX_BEST = 20,
	INTELLECTUAL_BENEFICENCE_MAIN = 21,
	GENERATIONS_DIALOG = 22,
}
