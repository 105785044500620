import { FC } from "react";
import s from "./styles.module.sass";
import clsx from "clsx";
import NextLink from "@shared/wrappers/NextLink";

interface ICardPaper {
	children: React.ReactNode;
	className?: string;
	onClick?: () => void;
	href?: string;
}

const CardPaper: FC<ICardPaper> = ({ children, className, href, onClick }) => {
	const resultClassName = clsx(s.cardPaper, className);

	return (
		<>
			{href !== undefined ? (
				<NextLink onClick={onClick} className={resultClassName} href={href}>
					{children}
				</NextLink>
			) : (
				<div className={resultClassName}>{children}</div>
			)}
		</>
	);
};

export default CardPaper;
